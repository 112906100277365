<template>
  <c-box
    w="100%"
    mx="auto"
    :h="['calc(100vh - 62px)', 'auto']"
    :bg="['#F2F2F2', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['1rem', '1.5rem']"
    :min-height="['unset', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="breadcrumbLabel"
      />
    </Portal>
    <c-box
      :w="['100%', '600px']"
      mx="auto"
    >
      <c-flex
        v-if="isChooseNutritionist"
        w="100%"
        flex-direction="row"
        align-items="center"
        justify-content="center"
        mb="2rem"
      >
        <c-flex
          flex-direction="column"
          justify-content="center"
          align-items="center"
          :font-weight="['400', '500']"
          :font-size="['12px', '16px']"
          :color="
            failed ? '#D32737' : (transactionId || programId || kuesionerId ? 'primary.400' : '#C4C4C4')
          "
        >
          <c-badge
            d="flex"
            align-items="center"
            justify-content="center"
            mx="2"
            :font-size="['14px', '18px']"
            :bg="
              failed ? '#D32737' : (transactionId || programId || kuesionerId ? 'primary.400' : '#C4C4C4')
            "
            color="white"
            border-radius="50%"
            text-align="center"
            line-height="32px"
            :w="['32px', '50px']"
            :h="['32px', '50px']"
          >
            1
          </c-badge>
          Pilih Program
        </c-flex>
        <c-image
          flex-shrink="0"
          :src="transactionId || programId || kuesionerId ? require('@/assets/icon-chevron-right-green-alt.svg') : require('@/assets/icon-chevron-right-grey-alt.svg')"
          alt="icon arrow"
          w="25px"
          :mx="['4px', '8px']"
        />
        <c-flex
          flex-direction="column"
          justify-content="center"
          align-items="center"
          :font-weight="['400', '500']"
          :font-size="['12px', '16px']"
          :color="
            programId || kuesionerId ? 'primary.400' : '#C4C4C4'
          "
        >
          <c-badge
            d="flex"
            align-items="center"
            justify-content="center"
            mx="2"
            :font-size="['14px', '18px']"
            :bg="
              programId || kuesionerId ? 'primary.400' : '#C4C4C4'
            "
            color="white"
            border-radius="50%"
            text-align="center"
            line-height="32px"
            :w="['32px', '50px']"
            :h="['32px', '50px']"
          >
            2
          </c-badge>
          Pilih Ahli Gizi
        </c-flex>
        <c-image
          v-if="!isSkipQuestionnaire || kuesionerId"
          flex-shrink="0"
          :src="programId || kuesionerId ? require('@/assets/icon-chevron-right-green-alt.svg') : require('@/assets/icon-chevron-right-grey-alt.svg')"
          alt="icon arrow"
          w="25px"
          :mx="['4px', '8px']"
        />
        <c-flex
          v-if="!isSkipQuestionnaire || kuesionerId"
          flex-direction="column"
          justify-content="center"
          align-items="center"
          :font-weight="['400', '500']"
          :font-size="['12px', '16px']"
          :color="
            kuesionerId ? 'primary.400' : '#C4C4C4'
          "
        >
          <c-badge
            d="flex"
            align-items="center"
            justify-content="center"
            mx="2"
            :font-size="['14px', '18px']"
            :bg="
              kuesionerId ? 'primary.400' : '#C4C4C4'
            "
            color="white"
            border-radius="50%"
            text-align="center"
            line-height="32px"
            :w="['32px', '50px']"
            :h="['32px', '50px']"
          >
            3
          </c-badge>
          Isi Formulir Gizi
        </c-flex>
      </c-flex>
      <c-flex
        flex-direction="column"
        justify-content="center"
        align-items="center"
        w="100%"
        px="2rem"
        :mt="isChooseNutritionist ? '0' : '2rem'"
      >
        <c-text
          font-family="Roboto"
          :font-size="['18px', '20px']"
          font-weight="700"
          mb="16px"
          text-align="center"
        >
          {{ textDetail.title }}
        </c-text>
        <c-image
          :src="failed ? require('@/assets/images/illustration-6-raw.png') : require('@/assets/paid.png')"
          mx="auto"
          :w="['200px', '300px']"
          margin-bottom="30px"
        />
        <c-text
          font-family="Roboto"
          :font-size="['14px', '16px']"
          color="#333"
          text-align="center"
          mb="16px"
          v-html="textDetail.description"
        />
        <c-button
          as="router-link"
          :to="buttonAction.to"
          background="#008C81"
          color="#FFF"
          py="10px"
          px="30px"
          h="50px"
          :w="['100%', '400px']"
          font-family="Roboto"
          :font-size="['14px', '16px']"
          font-weight="500"
          border-radius="50px"
        >
          {{ buttonAction.label }}
        </c-button>
      </c-flex>
    </c-box>
  </c-box>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'

export default {
  name: 'CheckoutDone',
  components: {
    BreadcrumbPath,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      successMessages: 'clients/successPayload',
      isSkipQuestionnaire: 'clients/isSkipQuestionnaire',
    }),
    axios() {
      return this.$store.getters.axios
    },
    failed() {
      return this.$route.query.s == 'failed' ?? false
    },
    transactionId() {
      return this.$route.query.transaction_id
    },
    programId() {
      return this.$route.query.program_id
    },
    kuesionerId() {
      return this.$route.query.kuesioner_id
    },
    isChooseNutritionist() {
      return this.$route.query.choose_nutri == 0 || this.$route.query.choose_nutri == false ? false : true
    },
    breadcrumbLabel() {
      if (this.transactionId) {
        return [
          {
            label: 'Dashboard',
            href: '/',
          },
          {
            label: 'Keranjang',
            href: '/cart',
          },
          {
            label: 'Checkout',
            isCurrent: true,
          },
        ]
      }
      if (this.programId) {
        return [
          {
            label: 'Dashboard',
            href: '/',
          },
          {
            label: 'Keranjang',
            href: '/cart',
          },
          {
            label: 'Pilih Ahli Gizi',
            isCurrent: true,
          },
        ]
      }
      if (this.kuesionerId) {
        return [
          {
            label: 'Dashboard',
            href: '/',
          },
          {
            label: 'Formulir Gizi',
            isCurrent: true,
          },
        ]
      }
      return []
    },
    textDetail() {
      if (this.transactionId) {
        if (this.failed) {
          return {
            title: 'Pembayaran Gagal!',
            description: 'Ups! pembayaran kamu gagal, pesanan otomatis dibatalkan, beli ulang pesananmu ya.',
          }
        }
        return {
          title: 'Pembayaran Diterima!',
          description: !this.isChooseNutritionist ? 'Pembayaran berhasil! Durasi program kamu akan otomatis diperpanjang dengan program yang sebelumnya' : 'Pembayaran berhasil! Silahkan pilih ahli gizi untuk tahap selanjutnya',
        }
      }

      if (this.programId) {
        return {
          title: 'Ahli Gizi Berhasil Dipilih!',
          description: `Anda telah memilih ahli gizi! ${this.isSkipQuestionnaire ? 'Masa Coaching dimulai dalam 1x24 jam. Setelah ini Ahli Gizi akan menghubungimu melalui Whatsapp, silahkan ditunggu ya.<br> All the best for your program!' : 'Silahkan lakukan pengisian formulir gizi untuk tahap selanjutnya'}`,
        }
      }

      if (this.kuesionerId) {
        return {
          title: 'Submit Berhasil!',
          description: 'Masa Coaching dimulai 1x24 jam setelah pengisian formulir gizi. Setelah ini Ahli Gizi akan menghubungimu melalui Whatsapp, silahkan ditunggu ya. All the best for your program!',
        }
      }

      return {
        title: '',
        description: '',
      }
    },
    buttonAction() {
      if (this.transactionId) {
        if (this.failed) {
          return {
            label: 'Cek Riwayat Transaksi',
            to: { name: 'client.profile.historyTransaction' },
          }
        } else if (!this.isChooseNutritionist) {
          return {
            label: 'Cek Program',
            to: this.programId ? { name: 'client.profile.programDetail', params: { programId: this.programId } } : { name: 'client.profile.programHistory' },
          }
        }
        return {
          label: 'Pilih Ahli Gizi',
          to: { name: 'client.nutritionists' },
        }
      }
      if (this.programId) {
        return {
          label: this.isSkipQuestionnaire ? 'Beranda' : 'Isi Formulir Gizi',
          to: this.isSkipQuestionnaire ? '/' : { name: 'client.kuisioner', params: { programId: this.programId } },
        }
      }
      if (this.kuesionerId) {
        return {
          label: 'Beranda',
          to: '/',
        }
      }
      return {
        label: 'Beranda',
        to: '/',
      }
    },
  },
  mounted() {
    this.setFetchPrograms(true)
  },
  methods: {
    ...mapMutations({
      setFetchPrograms: 'clients/setFetchPrograms',
    }),
  },
}
</script>
