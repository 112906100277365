var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "mx": "auto",
      "h": ['calc(100vh - 62px)', 'auto'],
      "bg": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['1rem', '1.5rem'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": _vm.breadcrumbLabel
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['100%', '600px'],
      "mx": "auto"
    }
  }, [_vm.isChooseNutritionist ? _c('c-flex', {
    attrs: {
      "w": "100%",
      "flex-direction": "row",
      "align-items": "center",
      "justify-content": "center",
      "mb": "2rem"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "font-weight": ['400', '500'],
      "font-size": ['12px', '16px'],
      "color": _vm.failed ? '#D32737' : _vm.transactionId || _vm.programId || _vm.kuesionerId ? 'primary.400' : '#C4C4C4'
    }
  }, [_c('c-badge', {
    attrs: {
      "d": "flex",
      "align-items": "center",
      "justify-content": "center",
      "mx": "2",
      "font-size": ['14px', '18px'],
      "bg": _vm.failed ? '#D32737' : _vm.transactionId || _vm.programId || _vm.kuesionerId ? 'primary.400' : '#C4C4C4',
      "color": "white",
      "border-radius": "50%",
      "text-align": "center",
      "line-height": "32px",
      "w": ['32px', '50px'],
      "h": ['32px', '50px']
    }
  }, [_vm._v(" 1 ")]), _vm._v(" Pilih Program ")], 1), _c('c-image', {
    attrs: {
      "flex-shrink": "0",
      "src": _vm.transactionId || _vm.programId || _vm.kuesionerId ? require('@/assets/icon-chevron-right-green-alt.svg') : require('@/assets/icon-chevron-right-grey-alt.svg'),
      "alt": "icon arrow",
      "w": "25px",
      "mx": ['4px', '8px']
    }
  }), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "font-weight": ['400', '500'],
      "font-size": ['12px', '16px'],
      "color": _vm.programId || _vm.kuesionerId ? 'primary.400' : '#C4C4C4'
    }
  }, [_c('c-badge', {
    attrs: {
      "d": "flex",
      "align-items": "center",
      "justify-content": "center",
      "mx": "2",
      "font-size": ['14px', '18px'],
      "bg": _vm.programId || _vm.kuesionerId ? 'primary.400' : '#C4C4C4',
      "color": "white",
      "border-radius": "50%",
      "text-align": "center",
      "line-height": "32px",
      "w": ['32px', '50px'],
      "h": ['32px', '50px']
    }
  }, [_vm._v(" 2 ")]), _vm._v(" Pilih Ahli Gizi ")], 1), !_vm.isSkipQuestionnaire || _vm.kuesionerId ? _c('c-image', {
    attrs: {
      "flex-shrink": "0",
      "src": _vm.programId || _vm.kuesionerId ? require('@/assets/icon-chevron-right-green-alt.svg') : require('@/assets/icon-chevron-right-grey-alt.svg'),
      "alt": "icon arrow",
      "w": "25px",
      "mx": ['4px', '8px']
    }
  }) : _vm._e(), !_vm.isSkipQuestionnaire || _vm.kuesionerId ? _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "font-weight": ['400', '500'],
      "font-size": ['12px', '16px'],
      "color": _vm.kuesionerId ? 'primary.400' : '#C4C4C4'
    }
  }, [_c('c-badge', {
    attrs: {
      "d": "flex",
      "align-items": "center",
      "justify-content": "center",
      "mx": "2",
      "font-size": ['14px', '18px'],
      "bg": _vm.kuesionerId ? 'primary.400' : '#C4C4C4',
      "color": "white",
      "border-radius": "50%",
      "text-align": "center",
      "line-height": "32px",
      "w": ['32px', '50px'],
      "h": ['32px', '50px']
    }
  }, [_vm._v(" 3 ")]), _vm._v(" Isi Formulir Gizi ")], 1) : _vm._e()], 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "w": "100%",
      "px": "2rem",
      "mt": _vm.isChooseNutritionist ? '0' : '2rem'
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['18px', '20px'],
      "font-weight": "700",
      "mb": "16px",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.textDetail.title) + " ")]), _c('c-image', {
    attrs: {
      "src": _vm.failed ? require('@/assets/images/illustration-6-raw.png') : require('@/assets/paid.png'),
      "mx": "auto",
      "w": ['200px', '300px'],
      "margin-bottom": "30px"
    }
  }), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "color": "#333",
      "text-align": "center",
      "mb": "16px"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.textDetail.description)
    }
  }), _c('c-button', {
    attrs: {
      "as": "router-link",
      "to": _vm.buttonAction.to,
      "background": "#008C81",
      "color": "#FFF",
      "py": "10px",
      "px": "30px",
      "h": "50px",
      "w": ['100%', '400px'],
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "font-weight": "500",
      "border-radius": "50px"
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonAction.label) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }